.ag-theme-material {
  font-family: var(--chakra-fonts-body) !important;
}
.ag-theme-material .ag-header-cell-label {
  color: var(--chakra-colors-black-primary) !important;
  font-weight: var(--chakra-fontWeights-bold) !important;
  font-size: 16px;
}
.ag-header-viewport {
  border-radius: 8px;
  border: none;
}

.ag-header-viewport:hover {
  background-color: transparent;
}

.ag-header {
  border: solid transparent !important;
}

.ag-header-cell {
  padding: 10px !important;
}

.ag-body-viewport {
  margin-top: 7px;
}

.row {
  border: solid transparent !important;
  border-top: 2px solid #f2f2f2 !important;
}

.ag-cell {
  display: flex;
  line-height: 20px !important;
  align-items: center;
  padding: 10px !important;
  font-size: 1em !important;
  text-transform: uppercase;
}

.rowActive {
  color: initial;
}
.rowInActive {
  color: var(--chakra-colors-gray-primary) !important;
}
